import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'
import { QuestionCircle } from '@styled-icons/fa-regular'

import { Layout } from '../layout'
import SEO from '../components/seo'

const Root = styled.section`
  margin: auto;
  width: 600px;
  min-height: 600px;
  .msg {
    text-align: center;
    font-size: 1.6rem;
    width: 75%;
  }
  a {
    text-decoration: none;
    color: ${p => p.theme.colors.blue};
  }
  a:hover {
    text-decoration: underline;
  }
`
const NumberWrap = styled.div`
  display: flex;
  line-height: 1;
  font-size: 11rem;
  font-weight: 500;
  font-family: Monospace;
  color: ${p => p.theme.colors.blue};
  span {
    padding: 0 0.25rem;
  }
  svg {
    vertical-align: baseline;
  }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
const Rotating = styled(QuestionCircle)`
  font-size: 8.5rem;
  color: ${p => p.theme.colors.blue};
  animation: ${rotate} 5s linear infinite;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Root>
      <NumberWrap>
        <span>4</span>
        <span>
          <Rotating size="8rem" />
        </span>
        <span>4</span>
      </NumberWrap>
      <div className="msg">
        Nie ma strony pod tym adresem. Może zostałą przeniesiona, usunięta albo
        nigdy nie tutaj istaniała ?
        <p>
          Przejdź na <Link to="/">stronę domową</Link> i spróbuj z tamtąd.
        </p>
      </div>
    </Root>
  </Layout>
)

export default NotFoundPage
